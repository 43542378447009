<template>
  <div>
    <b-form @submit.stop.prevent="formOnSubmit">
      <div class="row no-gutters mx-n1">
        <!-- Input Date -->
        <div class="col-lg-3 col-md-6 col-12 px-1">
          <b-form-group
            id="input-group-date"
            label="Tanggal:"
            label-for="input-date"
          >
            <!-- <b-form-datepicker
              v-model="form.date"
              :disabled="purpose == 'edit' && form.appointment_status_id == 4"
              id="input-date"
              placeholder="Tentukan Tanggal"
              locale="id"
            ></b-form-datepicker> -->

            <b-input-group>
              <b-input-group-prepend>
                <b-form-datepicker
                  locale="id"
                  placeholder="Tanggal"
                  id="input-date"
                  v-model="form.date"
                  button-only
                  now-button
                  :disabled="
                    purpose == 'edit' && form.appointment_status_id == 4
                  "
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                id="input-date"
                v-model="form.date"
                type="text"
                placeholder="YYYY-MM-DD"
                :disabled="purpose == 'edit' && form.appointment_status_id == 4"
                @input="formaterDate"
              ></b-form-input>
              <small class="text-danger">{{ error.date }}</small>
            </b-input-group>

          </b-form-group>
        </div>
      </div>

      <div class="row no-gutters align-items-center mx-n2">
        <!-- Input Modal Trigger -->
        <div class="col-lg-6 px-2">
          <b-form-group
            id="input-group-trigger-modal-patient"
            label="Pasien:"
            label-for="input-trigger-modal-patient"
          >
            <b-form-input
              id="input-trigger-modal-patient"
              v-model="display.patient_name"
              :disabled="purpose == 'edit' && form.appointment_status_id == 4"
              placeholder="Pilih Pasien"
              readonly
              @click="$bvModal.show('modal-patient')"
            ></b-form-input>
            <small class="text-danger">{{ error.patient_id }}</small>
          </b-form-group>
        </div>

        <!-- Input Doctor -->
        <div class="col-lg-6 px-2">
          <b-form-group
            v-if="currentUser.user_type_id != 1"
            id="input-group-doctor"
            label="Dokter:"
            label-for="input-doctor"
          >
            <treeselect
              v-model="form.doctor_id"
              :multiple="false"
              :disabled="purpose == 'edit' && form.appointment_status_id == 4"
              @select="inputDoctorOnSelect"
              :options="doctors"
            />
            <small class="text-danger">{{ error.doctor_id }}</small>
          </b-form-group>
        </div>
      </div>

      <b-form-group v-if="form.doctor_id != ''">
        <template>
          <div>
            <div
              style="font-size: 1.1rem"
              v-html="selectedDoctorScheduleText"
            ></div>
            <button class="btn-link" @click.stop.prevent="createDoctorSchedule">
              Tambah Jadwal
            </button>
          </div>
        </template>
      </b-form-group>

      <!-- Input Live Meeting Link -->
      <b-form-group id="input-group-live-meeting-link">
        <label for="input-live-meeting-link">
          Tautan Meeting:
          <em class="text-muted">opsional</em>
        </label>
        <b-form-input
          id="input-live-meeting-link"
          v-model="form.live_meeting_link"
          placeholder="Tautan Meeting"
        ></b-form-input>
      </b-form-group>

      <div class="row no-gutters mx-n2">
        <!-- Input Action Types -->
        <div class="col-lg-6 col-12 px-2">
          <b-form-group id="input-group-action_type">
            <label for="input-action-type">
              Jenis Tindakan:
              <em class="text-muted">opsional</em>
            </label>
            <treeselect
              v-model="form.action_type_id"
              :multiple="false"
              placeholder="Pilih Jenis Tindakan"
              :options="actionTypes"
            />
            <small class="text-danger">{{ error.action_type_id }}</small>
          </b-form-group>
        </div>

        <!-- Input Time Slot -->
        <div class="col-lg-6 col-12 px-2">
          <b-form-group
            id="input-group-time-slot"
            label="Slot Waktu:"
            label-for="input-time-slot"
          >
            <b-form-select
              v-model="form.doctor_time_slot_id"
              :options="timeSlots"
              :disabled="purpose == 'edit' && form.appointment_status_id == 4"
              placeholder="Slot Waktu"
            ></b-form-select>
            <small class="text-danger">{{ error.doctor_time_slot_id }}</small>
          </b-form-group>
        </div>
      </div>

      <!-- Input Quote Time Slot -->
      <div
        class="row no-gutters justify-content-end mx-n2"
        v-if="form.doctor_time_slot_id == 'custom'"
      >
        <div class="col-lg-3 col-12 px-2">
          <!-- <b-form-group
            id="input-group-quote-start-time"
            label="Jam Mulai:"
            label-for="input-quote-start-time"
          >
            <b-form-timepicker
              v-model="form.quote_start_time"
              placeholder="Jam Mulai"
              label-now-button="Waktu Sekarang"
              locale="id"
              now-button
              reset-button
              minutes-step="5"
              @hidden="inputQuoteStartTimeOnHidden"
            ></b-form-timepicker>
            <small class="text-danger">{{ error.quote_start_time }}</small>
          </b-form-group> -->

          <label for="input-start-time"> Jam Mulai</label>
          <b-input-group>
            <b-input-group-prepend>
              <b-form-timepicker
                v-model="form.quote_start_time"
                locale="id"
                placeholder="Waktu"
                button-only
                now-button
                reset-button
                minutes-step="5"
              ></b-form-timepicker>
            </b-input-group-prepend>

            <b-form-input
              id="input-start-time"
              v-model="form.quote_start_time"
              type="text"
              placeholder="HH:MM:SS"
              @input="formaterTimeStart"
            ></b-form-input>
            <small class="text-danger">{{ error.quote_start_time }}</small>
          </b-input-group>
        </div>

        <div class="col-lg-3 col-12 px-2">
          <!-- <b-form-group
            id="input-group-quote-end-time"
            label="Jam Selesai:"
            label-for="input-quote-end-time"
          >
            <b-form-timepicker
              v-model="form.quote_end_time"
              locale="id"
              placeholder="Jam Selesai"
              now-button
              label-now-button="Waktu Sekarang"
              reset-button
              minutes-step="5"
              @hidden="inputQuoteEndTimeOnHidden"
            ></b-form-timepicker>
            <small class="text-danger">{{ error.quote_end_time }}</small>
          </b-form-group> -->

          <label for="input-end-time"> Jam Selesai</label>
          <b-input-group>
            <b-input-group-prepend>
              <b-form-timepicker
                v-model="form.quote_end_time"
                locale="id"
                placeholder="Waktu"
                button-only
                now-button
                reset-button
                minutes-step="5"
              ></b-form-timepicker>
            </b-input-group-prepend>

            <b-form-input
              id="input-end-time"
              v-model="form.quote_end_time"
              type="text"
              placeholder="HH:MM:SS"
              @input="formaterTimeEnd"
            ></b-form-input>
            <small class="text-danger">{{ error.quote_end_time }}</small>
          </b-input-group>
        </div>
      </div>

      <!-- Input Room -->
      <b-form-group id="input-group-room">
        <label for="input-room">
          Ruangan:
          <em class="text-muted">opsional</em>
        </label>
        <treeselect
          v-model="form.room_id"
          :multiple="false"
          :options="rooms"
          placeholder="Pilih Ruangan"
        />
        <small class="text-danger">{{ error.room_id }}</small>
      </b-form-group>

      <!-- Input Remarks / Note -->
      <b-form-group id="input-group-notes">
        <label for="input-notes">
          Catatan:
          <em class="text-muted">opsional</em>
        </label>
        <b-form-input
          id="input-notes"
          v-model="form.notes"
          placeholder="Catatan"
        ></b-form-input>
      </b-form-group>

      <!-- Input Status -->
      <b-form-group
        id="input-group-status"
        label="Status Perjanjian:"
        label-for="input-status"
      >
        <b-form-select
          id="input-status"
          v-model="form.appointment_status_id"
          :disabled="purpose == 'edit' && form.appointment_status_id == 4"
          :options="statuses"
        ></b-form-select>
        <small class="text-danger">{{ error.appointment_status_id }}</small>
      </b-form-group>

      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        class="ml-2"
        variant="default"
        @click="$router.push('/appointment/list/0')"
        >Batal</b-button
      >
    </b-form>

    <ModalPatient
      :selectedDoctor="form.doctor_id.toString()"
      @chosenPatient="setPatient"
      @submitedPatient="setPatient"
    />
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import ModalPatient from "@/component/general/ModalPatient.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import AppointmentModule from "@/core/modules/AppointmentModule.js";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import {
  getUser,
  getConfiguration,
  setAppointmentForm,
  getAppointmentForm,
  removeAppointmentForm,
} from "@/core/services/jwt.service.js";

export default {
  props: {
    route: String,
    form: Object,
    purpose: String,
  },

  components: {
    Card,
    ModalPatient,
    Treeselect,
  },

  data() {
    return {
      // Display Data
      display: {
        patient_name: "",
      },
      // Error
      error: {
        patient_id: "",
        doctor_id: "",
        registration_date: "",
        time_slot_id: "",
        action_type_id: "",
        quote_start_time: "",
        quote_end_time: "",
        appointment_status_id: "",
      },
      // Options
      actionTypes: [],
      timeSlots: [{ text: "Pilih Slot Waktu", value: "", disabled: true }],
      rooms: [{ label: "Pilih Ruangan", id: "", isDisabled: true }],
      doctors: [],
      statuses: [],
      // Other
      doctorDisabled: this.purpose == "edit" ? true : false,
      selectedDoctorSchedule: [],
      selectedDoctorScheduleText: "",
      currentUser: getUser(),
    };
  },

  methods: {
    formaterDate(val) {
      if (val.length == 4) {
        this.form.date = this.form.date + "-";
      }
      if (val.length == 7) {
        this.form.date = this.form.date + "-";
      }
    },

    formaterTimeStart(val) {
      if (val.length == 2) {
        this.form.quote_start_time = this.form.quote_start_time + ":";
      }
      if (val.length == 5) {
        this.form.quote_start_time = this.form.quote_start_time + ":";
      }
    },

    formaterTimeEnd(val) {
      if (val.length == 2) {
        this.form.quote_end_time = this.form.quote_end_time + ":";
      }
      if (val.length == 5) {
        this.form.quote_end_time = this.form.quote_end_time + ":";
      }
    },

    setPatient(value) {
      this.form.patient_id = value.id;
      this.display.patient_name = value.name;
      if (this.currentUser.user_type_id != 1) {
        this.form.doctor_id = "";
        this.getDoctors();
      }
      this.doctorDisabled = false;
      this.$bvModal.hide("modal-patient");
    },

    async inputDoctorOnSelect(evt) {
      await evt.id;
      this.selectedDoctorSchedule = await module.get(
        `schedules/get-by-doctor/${evt.id}`
      );
      this.setScheduleText(this.selectedDoctorSchedule);
    },

    inputQuoteStartTimeOnHidden() {
      this.getRooms();
    },

    inputQuoteEndTimeOnHidden() {
      this.getRooms();
    },

    setScheduleText(items) {
      if (items.length != 0) {
        let text = `Jadwal Praktek ${items[0].doctor_name} <br>`;
        items.forEach(function (value, index) {
          text += `<b>${value.day_name} ${value.start_time} - ${value.end_time}</b>`;
          if (items.length > 1 && index != items.length - 1) {
            text += ", ";
          }
        });
        this.selectedDoctorScheduleText = text;
      } else {
        this.selectedDoctorScheduleText = "Belum Memiliki Jadwal";
      }
    },

    createDoctorSchedule() {
      setAppointmentForm(this.form);
      this.$router.push(
        `/schedule/list?doctor_id=${this.form.doctor_id}&redirect=appointment-${this.purpose}`
      );
    },

    async getRooms() {
      let timeSlot;
      if (this.form.doctor_time_slot_id != "custom") {
        timeSlot = this.timeSlots.find(
          (x) => x.value == this.form.doctor_time_slot_id
        );
      } else {
        if (
          !this.form.quote_start_time == "" ||
          !this.form.quote_end_time == ""
        ) {
          timeSlot = {
            start_time: this.form.quote_start_time,
            end_time: this.form.quote_end_time,
          };
        }
      }
      if (typeof timeSlot != "undefined") {
        var response = await AppointmentModule.getUnusedRooms(
          this.form.date,
          timeSlot.start_time,
          timeSlot.end_time,
          this.form.id
        );
      } else {
        var response = await AppointmentModule.getRooms();
      }
      this.rooms = response;
    },

    async getActionTypes() {
      let response = await module.setTreeSelect("payment-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.actionTypes = response.data;
        this.actionTypes.unshift({
          label: "Pilih Jenis Tindakan",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getDoctors() {
      let route;
      if (
        typeof this.form.patient_id !== "undefined" &&
        this.form.patient_id != ""
      ) {
        console.info("ruined");
        route = `doctors/get-by-patient/${this.form.patient_id}`;
      } else {
        route = "doctors";
      }
      let response = await module.setDoctorTreeSelect(route);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Pilih Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getAppointmentStatuses() {
      let response = await module.setSelectOption("appointment-statuses");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.statuses = response.data;
        this.statuses.unshift({
          text: "Pilih Status",
          value: "",
          disabled: true,
        });
      }
    },

    async getTimeSlots(date, doctorId) {
      var dateObj = new Date(date);
      var weekday = dateObj.toLocaleString("id-ID", { weekday: "long" });
      if (this.purpose == "add") {
        this.timeSlots = await AppointmentModule.getTimeSlots(
          date,
          weekday,
          doctorId
        );
      } else if (this.purpose == "edit") {
        this.timeSlots = await AppointmentModule.getTimeSlots(
          date,
          weekday,
          doctorId
        );
      }
    },

    async formOnSubmit() {
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        removeAppointmentForm();
        this.$root.$emit("UpdateNewAppointment");
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/appointment/list/0");
      }
    },
  },

  watch: {
    "form.patient_id": function (newVal) {
      this.getDoctors();
    },
    "form.patient_name": function (newVal, oldVal) {
      this.display.patient_name = newVal;
    },
    "form.doctor_time_slot_id": function (newVal, oldVal) {
      this.getRooms();
    },
    "form.date": function (newVal, oldVal) {
      if (this.form.doctor_id != "") {
        this.getTimeSlots(newVal, this.form.doctor_id);
      }
    },
    "form.doctor_id": async function (newVal, oldVal) {
      if (typeof newVal === "undefined") this.form.doctor_id = "";
      if (this.form.doctor_id != "") {
        this.selectedDoctorScheduleText = "";
        this.selectedDoctorSchedule = await module.get(
          `schedules/get-by-doctor/${newVal}`
        );
        this.setScheduleText(this.selectedDoctorSchedule);
        this.getTimeSlots(this.form.date, newVal);
      }
    },
  },

  mounted() {
    // Get Doctors
    this.getDoctors();
    if (this.currentUser.user_type_id == 1) {
      this.form.doctor_id = this.currentUser.id;
    }
    // Get Action Types
    this.getActionTypes();
    // Get AppointmentStatuses
    this.getAppointmentStatuses();
    // set form
    if (getAppointmentForm() != null) {
      this.form = getAppointmentForm();
    }
  },
};
</script>

<style>
</style>